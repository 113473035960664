<template>
  <div class="w-100 my-5">
    <div class="box-white d-flex flex-column">
      <div class="row w-100 mt-5 vertical-center">
        <span class="col-sm-2 m-1 font-bold font-12 alpha-07">Organizer ID</span>
        <input class="col-sm m-1 font-12" v-model="organizerId" />
      </div>
      <div class="row w-100 mt-5 vertical-center">
        <span class="col-sm-2 m-1 font-bold font-12 alpha-07">Handling Fee Percent</span>
        <input class="col-sm m-1 font-12" v-model="handlingFeePercent" />
      </div>
      <div class="my-5 d-flex justify-content-end">
        <v-btn class="mx-5 button-normal" small @click="setTicketHandlingFeeTask" :disabled="(!organizerId || !handlingFeePercent)">
          <span>Set Ticket Handling Fee</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../main';
import { showLoading } from '../../../functions';

export default {
  name: 'EditDocument',
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    }
  },
  data() {
    return {
      organizerId: '',
      handlingFeePercent: 0
    }
  },
  mounted() {
    if (window.location.hostname !== 'localhost') {
      this.$router.push('/');
    }
  },
  methods: {
    setTicketHandlingFeeTask() {
      const handlingFeePercent = parseFloat(this.handlingFeePercent);
      if (!this.organizerId) {
        this.$toast.info('Please enter Organizer ID.');
        return;
      }
      if (!handlingFeePercent) {
        this.$toast.info('Please enter Handling Fee Percent.');
        return;
      }
      if (!confirm('Really?')) {
        return;
      }
      const params = {
        functionName: 'setTicketHandlingFee',
        doSet: false,
        organizerId: this.organizerId,
        handlingFeePercent: handlingFeePercent
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, this.ADMIN_FUNCTION);
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.$toast.success(this.$t('success'));
        } else {
          this.$toast.error(response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>